import React, { Component } from 'react';
import doctorVector from '../images/doctor-vector.png';
import patientsHome from '../images/patients-home.png';
import microscope from '../images/microscope.png';
import { Link } from 'react-router-dom';
import './DynamicLinks.css';

export class DynamicLinks extends Component {
    static displayName = DynamicLinks.name;

    render() {
        return (
            <div class="desktopDiv" >
                {/*<br />*/}
                <div class="services-agile">
                    <div>
                        <img src={doctorVector} alt=" " />
                    </div>
                    <p class="card-title"><b>PHYSICIANS</b></p><br />
                    <p>&nbsp; &nbsp; We can bring remotely-monitored Video EEG services to your practice.</p>
                    <Link to="/options">Learn More</Link>
                </div>
                {/*<br />*/}
                <div class="services-agile-research">
                    <div>
                        <img src={microscope} alt=" " />
                    </div>
                    <p class="card-title"><b>RESEARCHERS</b></p><br />
                    <p>&nbsp; &nbsp; ANS can provide the EEG expertise and equipment for your project.</p>
                    <Link to="/researchers">Learn More</Link>
                </div>
                {/*<br />*/}
                <div class="services-agile-patients">
                    <div>
                        <img src={patientsHome} alt=" " />
                    </div>
                    <p class="card-title"><b>PATIENTS</b></p><br />
                    <p>&nbsp; &nbsp; Have your EEG in your own home with the same quality as a hospital.</p>
                    <Link to="/prepare">Learn More</Link>
                </div>

                {/*<br />*/}

            </div>


        );
    }
}
import React, { Component } from 'react';
import './Footer.css';
import noticePDF from '../docs/Privacy Practices 2018.pdf'
import privatePDF from '../docs/PRIVACY POLICY.pdf'
import { Link } from 'react-router-dom';

export class Footer extends Component {
    static displayName = Footer.name;


    render() {
        return (
            <div className="footer-body">
                <hr />
                <article className="container-footer">
                    <div>
                        <section className="site-map">
                            <b>SITE MAP:</b><br />
                            <Link to="/">• HOME</Link><br />
                            <Link to="/about-us">• ABOUT</Link><br />
                            <Link to="/options">• PHYSICIANS</Link><br />
                            <Link to="/researchers">• RESEARCHERS</Link><br />
                            <Link to="/prepare">• PATIENTS</Link><br />
                            <Link to="/contact-us">• CONTACT US</Link>
                        </section>

                        <section>
                            <b>Ambulatory Neurological Services, LLC</b>
                            <p>603 Fox Glen Ct</p>
                            <p>Barrington, Illinois 60010</p><br /><br />
                        </section>

                        <section>
                            <p>(888) 447-5904</p>
                            <p>FAX (866) 619-2123</p>
                            <p> info@anseeg.com</p><br /><br />
                        </section>

                        <section>
                            <a href={noticePDF} >NOTICE OF PRIVACY PRACTICES</a><br />
                            <a href={privatePDF}>PRIVACY POLICY</a><br /><br /><br />
                        </section>
                    </div>
                </article>
                <footer>
                    <p> Content copyright 2018. Ambulatory Neurological Services. All rights reserved.</p><br /><br />
                </footer>
            </div>

        );
    }
}
import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import logo from '../images/ANSlogoSite.png'; 
import news from '../docs/Merger.pdf';
import releaseForm from '../docs/ANS Information Release Form 12-6-18.pdf';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
        
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return (
            <header className="navBarSticky">
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                    <Container>
                        <NavbarBrand tag={Link} to="/"><img src={logo} alt="ANS Logo" className="AnsLogo" /></NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                <NavItem>
                                    <NavLink className="text-dark" href={news}><i>NEWS</i></NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/">HOME</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/about-us">ABOUT</NavLink>
                                </NavItem>
                                <NavItem className="dropdown">
                                    <NavItem className="text-dropdown">PHYSICIANS</NavItem>
                                    <div className="dropdown-content">
                                        <ul>
                                            <NavLink tag={Link} className="text-dark-drop" to="/options">EEG OPTIONS</NavLink>
                                            <NavLink tag={Link} className="text-dark-drop" to="/physician-faq">PHYSICIAN FAQ</NavLink>
                                            
                                        </ul>
                                    </div>
                                    </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/researchers">RESEARCHERS</NavLink>
                                </NavItem>

                                <NavItem className="dropdown">
                                    <NavItem className="text-dropdown">PATIENTS</NavItem>
                                    <div className="dropdown-content">
                                        <ul>
                                            <NavLink tag={Link} className="text-dark-drop" to="/prepare">PREPARING FOR IN-HOME EEG</NavLink>
                                            <NavLink tag={Link} className="text-dark-drop" to="/during-eeg">DURING EEG</NavLink>
                                            <NavLink tag={Link} className="text-dark-drop" to="/advantage">THE ANS ADVANTAGE</NavLink>
                                            <NavLink className="text-dark-drop" href={releaseForm}>INFORMATION RELEASE FORM</NavLink>
                                            <NavLink tag={Link} className="text-dark-drop" to="/patient-faq">PATIENT FAQ</NavLink>

                                        </ul>
                                    </div>
                                </NavItem>

                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/testimonials">TESTIMONIALS</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/resources">RESOURCES</NavLink>
                                </NavItem>

                                <NavItem className="dropdown"> 
                                    <NavItem className="text-dropdown">CONTACT</NavItem>
                                    <div className="dropdown-content">
                                        <ul>
                                            <NavLink tag={Link} className="text-dark-drop" to="/contact-us">CONTACT US</NavLink>
                                            <NavLink tag={Link} className="text-dark-drop" to="/offices">OFFICES</NavLink>
                                            <NavLink tag={Link} className="text-dark-drop" to="/schedule">SCHEDULE AN EEG</NavLink>
                                            <NavLink tag={Link} className="text-dark-drop" to="/billing">BILLING</NavLink>
                                        </ul>
                                    </div>
                                </NavItem>
                                <NavItem>
                                     <NavLink tag={Link} className="text-dark" to="/hippa"><i><b>HIPPA NOTICE</b></i></NavLink>
                                </NavItem>

                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}

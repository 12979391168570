import React, { Component } from 'react';
import ANSMap from '../../images/ANSMap.png';
import '../Pages.css';

export class Offices extends Component {
    static displayName = Offices.name;

    render() {
        return (
            <div class="page-background">
                <div class="container-offices">
                    <div class="office-img-container"><img src={ANSMap} alt="map" />
                    </div>
                    <div class="offices-text">

                        <p><b>If you live in one of these green states (see map), you can receive an ANS EEG: ask your physician!</b><br /><br />
                            You can also give us a call or send an email for more information. </p>
                        <p>
                            Ambulatory Neurological Services, LLC<br />
                            603 Fox Glen Ct <br />
                            Barrington, Illinois 60010<br /><br />

                            (888) 447-5904<br />
                            FAX (866) 619-2123<br />
                            info@anseeg.com
                        </p>
                    </div>
                </div>
            </div>


        );
    }
}
import React, { Component } from 'react';
import TimeZoneMap from '../../images/TimeZoneMap.PNG';
import '../Pages.css';

export class Schedule extends Component {
    static displayName = Schedule.name;

    render() {
        return (
            <div class="container-offices">
                <div class="office-img-container"><img src={TimeZoneMap} /></div>
                <div class="offices-text">
                    <p class="schedule-header"><b>To Schedule an EEG</b> </p>
                    <p>
                        Please call one of our schedulers in the following areas, <br /> based on where you live:<br /><br />
                        <p class="timezone">• Eastern Time Zone: 888-447-5904<br /></p>
                        <p class="timezone">• Central Time Zone: 224-848-4334<br /></p>
                        <p class="timezone">• Mountain Time Zone: 801-999-4857<br /></p>
                        <p class="timezone">• Pacific Time Zone (including Arizona): 888-748-7287</p>
                        <br />
                    </p>
                </div>
            </div>
        );
    }
}
import React, { Component } from 'react';
import '../Pages.css';

export class PhysicianFAQ extends Component {
    static displayName = PhysicianFAQ.name;

    render() {
        return (
            <div>
                <div class="banner w3layouts">
                    <p class="headerText">PHYSICIAN FAQ</p>

                    <div class="container-pages">
                        <ul class="faqList paper">

                            <li>
                                <p>
                                    <b class="FAQhead">How do I review the EEG data collected on my patient?</b> <br /><br />
                                    <p class="indented">Your ANS account representative will set up your office and/or laptop
                                        computer with the appropriate links to connect to the ANS Online Review application. This
                                        Online Review Application will display all your patients’ data and allow you to dictate the
                                        study at that time.</p>
                                </p><br />
                                <p class="FAQend"></p>
                                <br />
                            </li><br />
                            <li>
                                <p>
                                    <b class="FAQhead" >How do I see my dictation or send it to another doctor or my
                                        billing
                                        department?</b> <br /><br />
                                    <p class="indented">Your EEG dictations are available on the ANS physician portal and can be
                                        viewed, downloaded, or printed as necessary. You can set up your staff and billing
                                        department with access to your studies to improve their ability to utilize the data.</p>
                                </p><br />
                                <p class="FAQend"></p>
                                <br />
                            </li><br />
                            <li>
                                <p>
                                    <b class="FAQhead">Is my Patient’s data safe and is your system HIPAA compliant?</b>
                                    <br /><br />
                                    <p class="indented">The ANS online data review for EEGs is provided via our own proprietary
                                        technology and unlike our competitors who rely on third-party platforms for data
                                        management, we manage our data on dedicated servers behind HIPAA-compliant firewalls. All
                                        data transfers are 100% HIPAA-compliant and transmitted using only the latest encryption technology.</p>
                                </p><br />
                                <p class="FAQend"></p>

                            </li>

                        </ul>

                    </div>
                </div>


            </div>
        );
    }
}
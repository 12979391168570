import React, { Component } from 'react';
import '../Pages.css';

export class Researchers extends Component {
    static displayName = Researchers.name;

    render() {
        return (
            <div class="banner-research w3layouts">
                <div>
                    <p class="headerText" >RESEARCH EEG SERVICES</p>

                    <div class="container-research faqList paper">
                        <div class="research-head">
                            ANS can provide the technicians, equipment and software to help you complete your research project with
                            confidence and the quality necessary in today’s demanding healthcare environment. <br /><br />
                            Because ANS is a technology leader and maintains its own network and develops its’ own EEG management
                            applications, we can customize a data review platform specifically for your study.
                        </div>
                        <br /><br />
                        <p class="FAQend"></p>
                        <ul class="faqSubList">

                            <li>

                                <b>Need to grant multiple physicians or research staff in diverse geographies access to your EEG
                                    data? </b><br />
                                <p class="research-p">We can tailor a review platform specifically for your study that
                                    allows different levels of data access.</p>
                            </li>
                            <li><b>Need reports customized for reviewing physicians?</b> <br />
                                <p class="research-p">We can build viewing and report templates customized to your study
                                    requirements and save you time and energy in managing your data.</p>
                            </li>
                            <li><b>Need help designing the EEG phase of your protocol? </b><br />
                                <p class="research-p">Give us a call and we can help you integrate the EEG testing in your
                                    study protocol while it’s still in the design phase.</p>
                                <br />
                                <p class="FAQend"></p><br />
                                For help with the EEG component of your research project, call us at 888-447-5904 extension #7 or
                                ask for the research department<br /><br />
                            </li>
                            <p class="FAQend"></p>


                        </ul>
                    </div>

                </div>
            </div>


        );
    }
}
import React, { Component } from 'react';
import '../Pages.css';

export class Testimonials extends Component {
    static displayName = Testimonials.name;

    render() {
        return (
            <div class="banner-testimonial w3layouts">
                <div>
                    <p class="headerText">TESTIMONIALS</p>

                    <div class="container-pages">
                        <div class="faqList paper ">
                        <ul >
                            <h3>Patient Testimonials</h3><br />
                            <p class="FAQend"></p>
                            <li>
                                "Thank you for providing such a great service! Doing my EEG at home instead of in the
                                hospital was a huge relief. If I ever need an EEG again, I will make sure my doctor
                                chooses ANS." <br /><br />
                                <i>Margaret R., Milwaukee, Wisconsin</i>
                            </li>
                            <li>
                                “I am so glad our doctor ordered our EEG with ANS. Our son has epilepsy and has had several in-home
                                EEGs
                                and they haven’t always gone well. Our ANS technician was so patient and careful with our son,
                                he was able to stay calm and happy through the whole setup. Thanks for a great experience!” <br /><br />
                                <i>Jennifer G. Barrington, Illinois</i>
                            </li>
                            <li>
                                “I am writing to let you know the tech who did my EEG was great.
                                She explained everything about the EEG before she started and she took the time
                                to go over everything again at the end. I would recommend using your company to anyone who needs an
                                EEG.”<br /><br />
                                <i>Brenda B. Dayton, Ohio</i>
                            </li>
                            <li>
                                “I am happy to say that my EEG was a success!
                                I was worried that it would be too uncomfortable to wear for three days but once I had it on, it
                                was no problem.
                                My wife did have some trouble remembering how to gel the leads but I called the technical support
                                number and they walked us through everything again. Thank you.”<br /><br />
                                <i>Ray W. Provo, Utah</i>
                            </li>
                            <li>
                                “I was hesitant to do my EEG because I thought it would be painful or at least very uncomfortable.
                                My EEG was actually very tolerable and the setup and monitoring did not hurt at all.
                                My technician was also very nice and helpful during the setup explaining everything to me and eased
                                my concerns greatly.”<br /><br />
                                <i>Fatima M, Kentucky</i>
                            </li>
                            <li>
                                “I’m glad my doctor referred us to ANS. Our son has a rare form of epilepsy and we have had many
                                EEGs both in the hospital and in our home.
                                None of those EEGs went as smoothly as the one performed by ANS.
                                We also requested a data disc to keep the information for our son’s permanent health records and
                                your company got it
                                to us quickly and without any hassle, unlike some of the other providers we have used. Thanks
                                again.”<br /><br />
                                <i>Joseph N, North Carolina</i>
                            </li>
                            <li>
                                “We live three hours from a major hospital and it takes a whole day to drive our daughter to and
                                from the doctor whenever she needs an EEG.
                                Long-term monitoring is even harder because we have to stay in the hospital with her during the
                                monitoring and it means my husband or I have to take off work.
                                ANS came to our home and did the setup without any issues and then remotely monitored the study to
                                make sure everything was OK. What a great service!”<br /><br />
                                <i>Mona P, Idaho</i>
                            </li>
                            <li>
                                “The whole EEG process was simple and much more comfortable than I expected.
                                After wearing the EEG for a couple hours, I hardly noticed I still had it on. Sleeping was also
                                much better than I expected and not a problem at all.”<br /><br />
                                <i>Martha E, West Virginia</i>
                            </li>
                        </ul>
                            <br />
                            <ul>
                        {/*<ul class="faqList paper testimonial">*/}
                            <h3>Physician Testimonials</h3><br />
                            <p class="FAQend"></p>
                            <li>
                                “ANS provides my patients with a comfortable, high-quality EEG that I can rely on time after time.”
                                <br /><br />
                                <i>Dr. Michael Hammer, MD</i>
                            </li>
                            <li>
                                “I have worked with multiple EEG providers over the years and ANS studies are the best I’ve seen.
                                The video quality of their studies is unparalleled in the industry and distinguishes them among
                                video EEG providers.”<br /><br />
                                <i>Dr. Christopher Inglese, MD and Epileptologist</i>
                            </li>
                            <li>
                                “As an epileptologist, I need high-quality EEG data delivered in a secure environment.
                                Thank you ANS for a great EEG and a great system!” <br /><br />
                                <i>Dr. Rajinder Singh, DO and Epileptologist</i>
                            </li>
                            <li>
                                “I expect a lot from an EEG service: quick scheduling, great technicians, accurate data
                                preparation, and a review system that works everywhere I work.
                                ANS delivers on my expectations and, more importantly, they help improve my level of patient care:
                                that’s why I have been using them for almost ten years.”
                                <br /><br />
                                <i>Dr. Sasidharan Taravath, MD</i>
                            </li>
                            <li>
                                I frequently find that the in-home alternative for EEG monitoring is preferable to a hospital
                                environment.
                                Many children are intimidated by all the stimulus found in a facility and if we can eliminate this
                                with an in-home option,
                                I will prefer that option every time.” <br /><br />
                                <i>Dr. Colin Van Orman, MD</i>
                            </li>

                            </ul>
                            </div>

                    </div>
                </div>

            </div>
        );
    }
}
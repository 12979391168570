import React, { Component } from 'react';
//import 'bootstrap/dist/css/bootstrap.min.css';
import {
    Button,
    Form,
    FormGroup,
    Input,
    Label
} from 'reactstrap';
import * as emailjs from 'emailjs-com'
//import axios from 'axios';
import '../Pages.css';

//const API_PATH = 'http://localhost:44475/ClientApp/api/index.php'; 

export class ContactUs extends Component {
    static displayName = ContactUs.name;

    state = {
        name: '',
        email: '',
        message: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipCode: '',
        daytimePhone: '',
        eveningPhone: '',

    }
    handleSubmit(e) {
        e.preventDefault()
        const { name, email, address1, address2, city, state, zipCode, daytimePhone, eveningPhone, message } = this.state
        let templateParams = {
            from_name: email,
            to_name: 'info@anseeg.com',
            name: name,
            address1: address1,
            address2: address2,
            city: city,
            state: state,
            zipCode: zipCode,
            daytimePhone: daytimePhone,
            eveningPhone: eveningPhone,
            message: message,
        }
        emailjs.send(
            'service_k449o3p',
            'template_3w8hgim',
            templateParams,
            'S63Ujgm9-CgPmkXax'
        )
        this.resetForm()
    }
    resetForm() {
        this.setState({
            name: '',
            email: '',
            message: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            zipCode: '',
            daytimePhone: '',
            eveningPhone: '',
            mailSent: false,
            error: null
        })
    }
    handleChange = (param, e) => {
        this.setState({ [param]: e.target.value })
    }

    //constructor(props) {
    //    super(props);
    //    this.state = {
    //        name: '',
    //        email: '',
    //        comments: '',
    //        address1: '',
    //        address2: '',
    //        city: '',
    //        state: '',
    //        zipCode: '',
    //        daytimePhone: '',
    //        eveningPhone: '',
    //        mailSent: false,
    //        error: null
    //    }
    //}

    //handleFormSubmit(event) {
    //    event.preventDefault();
    //    console.log(this.state);
    //    axios({
    //        method: 'post',
    //        url: `${API_PATH}`,
    //        headers: { 'content-type': 'application/json' },
    //        data: this.state
    //    })
    //        .then(result => {
    //            this.setState({
    //                mailSent: result.data.sent
    //            })
    //        })
    //        .catch(error => this.setState({ error: error.message }));
    //}


    render() {
        return (
            <div>
                <div class="banner-contact">
                    <div>
                        <p class="headerText">CONTACT US</p>
                    </div>
                    
                    <div className="contact-form">

                        {/*<Form className="form paper" onSubmit={this.handleSubmit.bind(this)}>*/}
                        <Form className="form paper" onSubmit={this.handleSubmit.bind(this)}>
                            <FormGroup class="FormGroup" controlId="formBasicEmail">
                                <Input
                                    type="text"
                                    name="name"
                                    id="name"
                                    placeholder="Full Name"
                                    value={this.state.name}
                                    onChange={this.handleChange.bind(this, 'name')}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    type="email"
                                    name="email"
                                    id="email"
                                    placeholder="Email Address"
                                    value={this.state.email}
                                    onChange={this.handleChange.bind(this, 'email')}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    type="text"
                                    name="address1"
                                    id="address1"
                                    placeholder="Address Street 1"
                                    value={this.state.address1}
                                    onChange={this.handleChange.bind(this, 'address1')}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    type="text"
                                    name="address2"
                                    id="address2"
                                    placeholder="Address Street 2"
                                    value={this.state.address2}
                                    onChange={this.handleChange.bind(this, 'address2')}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    type="text"
                                    name="city"
                                    id="city"
                                    placeholder="City"
                                    value={this.state.city}
                                    onChange={this.handleChange.bind(this, 'city')}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    type="text"
                                    name="state"
                                    id="state"
                                    placeholder="State"
                                    value={this.state.state}
                                    onChange={this.handleChange.bind(this, 'state')}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    type="text"
                                    name="zipCode"
                                    id="zipCode"
                                    placeholder="ZIP Code"
                                    value={this.state.zipCode}
                                    onChange={this.handleChange.bind(this, 'zipCode')}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    type="text"
                                    name="daytimePhone"
                                    id="daytimePhone"
                                    placeholder="Daytime Phone"
                                    value={this.state.daytimePhone}
                                    onChange={this.handleChange.bind(this, 'daytimePhone')}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    type="text"
                                    name="eveningPhone"
                                    id="eveningPhone"
                                    placeholder="Evening Phone"
                                    value={this.state.eveningPhone}
                                    onChange={this.handleChange.bind(this, 'eveningPhone')}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    type="textarea"
                                    name="message"
                                    id="message"
                                    placeholder="Comments"
                                    value={this.state.message}
                                    onChange={this.handleChange.bind(this, 'message')}

                                />
                            </FormGroup>
                            <div class="contact-submit">
                                <Button variant="primary" type="submit">Submit</Button>
                            </div>
                            <div>
                                {this.state.mailSent &&
                                    <div>Thank you for contcting us.</div>
                                }
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        );
    }
}
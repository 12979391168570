import React, { Component } from 'react';
import './About.css';

export class DynamicAbout extends Component {
	static displayName = DynamicAbout.name;

    render() {
        return (
            <div>
				<div class="w3layouts contact-grids" id="grid">
					<div class="col-md-4 contact-grid agile text-center animated wow slideInLeft" >
						<div class="contact-grid1 agileits-w3layouts">
							<h1>Our Company</h1><br />
							<p>ANS is a leading provider of electroencephalograms (EEGs) to patients throughout the United States. We are a
								pioneer in the field of providing in-home ambulatory and routine EEGs with over ten years’ experience. We remotely
								monitor all our studies to ensure the highest quality of data and greatest comfort to our patients.
							</p>
						</div>
					</div>
					<div class="col-md-4 contact-grid agileits text-center animated wow slideInUp" >
						<div class="contact-grid2  agileits-w3layouts">
							<h1>Our Team</h1><br />
							<p>We pride ourselves on having one of the most experienced management teams and neurological advisory boards in
								the industry. Our medical directors who oversee the testing process each have over fifteen years of neurology
								experience and our technicians are all registered with ABRET or in training to become registered technicians. </p>
						</div>
					</div>
					<div class="col-md-4 contact-grid w3 text-center animated wow slideInRight" >
						<div class="contact-grid3  agileits-w3layouts">
							<h1>Our Philosophy</h1><br />
							<p>Our goal is to provide the highest level of care for our patients and the best quality of data to their
								referring physicians. We do this by leading the industry with the best technology, both hardware and software,
								having the best technicians and EEG processes, and focusing on creating a safe, comfortable EEG experience for
								patients. </p>
						</div>
					</div>
				</div>

            </div>


        );
    }
}
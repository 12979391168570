import React, { Component } from 'react';
import '../Pages.css';

export class Hippa extends Component {
    static displayName = Hippa.name;

    render() {
        return (
            <div className="w3layouts">
                <div>
                    <p className="headerText-hippa">HIPAA BREACH NOTIFICATION</p>

                    <div className="container-pages">
                        {/*<ul className="faqList paper">*/}
                            <div className="et_pb_module et_pb_text et_pb_text_2  et_pb_text_align_left et_pb_bg_layout_light">




                                <div className="et_pb_text_inner"><p>The following contains important information about a breach from a phishing attack that potentially impacted the personal information of select patients of Ambulatory Neurological Services.</p>
                                    <p style={{textAlign: "left"}}><b>What Happened?<span className="Apple-converted-space">&nbsp;</span></b></p>
                                    <p style={{textAlign: "left"}}>On August 9th, 2023, a staff member of the company inadvertently disclosed patient information in response to an email from an unauthorized third party who was impersonating an executive at the company. On the same day, the staff member realized the patient information was disclosed to an unauthorized third party and notified the staff member’s supervisor of the issue. Although the unauthorized third party requested additional information, no additional information was disclosed on or following August 9th, 2023 to the unauthorized third party.<span className="Apple-converted-space">&nbsp;</span></p>
                                    <p style={{textAlign: "left"}}><b>What We are Doing<span className="Apple-converted-space">&nbsp;</span></b></p>
                                    <p><span>Upon learning of the issue, we commenced a prompt and thorough investigation. </span><span>Our findings revealed that a small number of individuals were affected by this breach. </span><span>We concluded the data that was accessed contained some of your personal information as described below. We have taken steps to secure our systems and train staff to prevent future breaches.<span className="Apple-converted-space">&nbsp;</span></span></p>
                                    <p style={{textAlign: "left"}}><b>What Information Was Involved?<span className="Apple-converted-space">&nbsp;</span></b></p>
                                    <p style={{textAlign: "left"}}>The information released includes your name, date of birth, and Current Procedural Terminology (CPT) codes. No other information was released. We are not aware of any unauthorized use of this information.<span className="Apple-converted-space">&nbsp;</span></p>
                                    <p style={{textAlign: "left"}}><b>What You Can Do<span className="Apple-converted-space">&nbsp;</span></b></p>
                                    <p style={{textAlign: "left"}}>We have no reason to believe that your information has been or will be misused as a direct result of this incident. Nevertheless, we encourage you to remain vigilant against incidents of identity theft and fraud by reviewing your account statements and monitoring your free credit reports for suspicious activity or errors. Out of an abundance of caution to protect you from potential misuse of your information<span>, you might want to take steps to protect yourself from identity theft and other forms of fraud.<span className="Apple-converted-space">&nbsp;</span></span></p>
                                    <ul style={{textAlign: "left"}}>
                                        <li>Consider placing a free “fraud alert” or “security freeze” on your credit reports with consumer reporting agencies<span>.<span className="Apple-converted-space">&nbsp;</span></span></li>
                                        <li>You can request one free credit report from those agencies each year to monitor any potentially fraudulent activity. For more information, you can visit the “Identity Theft” section of this website, and additional resources are available at https://www.consumer.ftc.gov/ and <a href="https://www.identitytheft.gov/" rel="noopener">https://www.identitytheft.gov/</a>.</li>
                                        <li>Monitor your credit report for any unauthorized activity.</li>
                                        <li>Be wary of any unsolicited emails or phone calls that ask for your personal information.</li>
                                        <li>Report any suspicious activity to the appropriate authorities.</li>
                                    </ul>
                                    <p style={{textAlign: "left"}}>If you detect any suspicious activity on an account, you should promptly notify the financial institution or company with which the account is maintained. You also should promptly report any fraudulent activity or any suspected incidence of identity theft to proper law enforcement authorities, your state attorney general, and/or the Federal Trade Commission (FTC).<span className="Apple-converted-space">&nbsp;</span></p>
                                    <p style={{textAlign: "left"}}>To file a complaint with the FTC, mail a letter to 600 Pennsylvania Avenue NW, Washington, DC 20580, go to <a href="http://www.ftc.gov/idtheft" rel="noopener"><span>www.ftc.gov/idtheft </span></a>or call 1-877-ID-THEFT (877-438-4338). Complaints filed with the FTC will be added to the FTC’s Identity Theft Data Clearinghouse, a database made available to law enforcement agencies.<span className="Apple-converted-space">&nbsp;</span></p>
                                    <p style={{textAlign: "left"}}>Even if you do not find any signs of fraud on your reports, we recommend that you check your credit reports at least every three months for the next year.<span className="Apple-converted-space">&nbsp;</span></p>
                                    <p style={{textAlign: "left"}}>For more information on identity theft, we suggest you visit the website of the Federal Trade Commission at <a href="http://www.consumer.gov/idtheft" rel="noopener"><span>www.consumer.gov/idtheft</span></a>.<span className="Apple-converted-space">&nbsp;</span></p>
                                    <p style={{textAlign: "left"}}><b>For More Information<span className="Apple-converted-space">&nbsp;</span></b></p>
                                    <p style={{textAlign: "left"}}>If there is anything Ambulatory Neurological Services can do to assist you, please call 888-447-5904.<span className="Apple-converted-space">&nbsp;</span></p>
                                    <p style={{textAlign: "left"}}>Again, please accept our apologies for this breach of our security protocols and the possible impact on you. We are committed to maintaining the privacy of personal information in our possession and have taken many precautions to safeguard it.<span className="Apple-converted-space">&nbsp;</span></p>
                                    <p style={{textAlign: "left"}}>Sincerely,<span className="Apple-converted-space">&nbsp;</span></p>
                                    <p style={{textAlign: "left"}}>Ambulatory Neurological Services<span className="Apple-converted-space">&nbsp;</span></p>
                                    <p style={{textAlign: "left"}}><b>Additional Resources<span className="Apple-converted-space">&nbsp;</span></b></p>
                                    <p style={{textAlign: "left"}}><b>Credit Reports<span className="Apple-converted-space">&nbsp;</span></b></p>
                                    <p style={{textAlign: "left"}}>To order a free copy of your credit report, visit <span>www.annualcreditreport.com</span>, call toll-free at (877) 322-8228, or complete the Annual Credit Report Request Form on the FTC website at <span>http://www.ftc.gov/bcp/edu/resources/forms/requestformfinal.pdf </span>and mail it to<span className="Apple-converted-space">&nbsp;</span></p>
                                    <p style={{textAlign: "left"}}>Annual Credit Report Request Service,</p>
                                    <p style={{textAlign: "left"}}>P.O. Box 105281,<span className="Apple-converted-space">&nbsp;</span></p>
                                    <p style={{textAlign: "left"}}>Atlanta, GA 30348-5281<span className="Apple-converted-space">&nbsp;</span></p>
                                    <p style={{textAlign: "left"}}>The three national credit bureaus provide free annual credit reports only through their websites, toll-free numbers, or request forms.<span className="Apple-converted-space">&nbsp;</span></p>
                                    <p style={{textAlign: "left"}}>When you receive your credit report, review it carefully. Look for accounts you did not open. Look in the “inquiries” section for the names of creditors from whom you haven’t requested credit. Some companies bill under names other than their store or commercial names. The credit bureau will be able to tell you when that is the case. Look in the “personal information” section for any inaccuracies in your information (such as home address and social security number).<span className="Apple-converted-space">&nbsp;</span></p>
                                    <p style={{textAlign: "left"}}>If you see anything you do not understand, call the credit bureau at the telephone number on the report. Errors in this information may be a warning sign of possible identity theft. You should notify the credit bureaus of any inaccuracies in your report, whether due to error or fraud, as soon as possible so the information can be reviewed and, if found to be in error, corrected. If there are accounts or charges you did not authorize, immediately notify the appropriate credit bureau by telephone and in writing.<span className="Apple-converted-space">&nbsp;</span></p>
                                    <p style={{textAlign: "left"}}><b>Fraud Alerts<span className="Apple-converted-space">&nbsp;</span></b></p>
                                    <p style={{textAlign: "left"}}>To protect yourself from possible identity theft, consider placing a fraud alert on your credit file. A fraud alert notifies you of an attempt by an unauthorized person to open a new credit account in your name. When a merchant checks the credit history of someone applying for credit, the merchant gets a notice that the applicant may be the victim of identity theft. The alert notifies the merchant to take steps to verify the identity of the applicant. You can place a free fraud alert on your credit report by calling any one of the toll-free fraud numbers provided below. You will reach an automated telephone system that allows you to flag your file with a fraud alert at all three credit bureaus. You can also place a fraud alert on your credit report online at the websites listed below.<span className="Apple-converted-space">&nbsp;</span></p>
                                    <p style={{textAlign: "left"}}><span className="Apple-converted-space"></span></p>
                                <table style={{ borderCollapse: "collapse", width: "100%", height: "240px", float: "left", border:"1" }}>
                                        <tbody>
                                        <tr style={{ height: "24px" }}>
                                            <td valign="top" style={{ width: "72.267097%", height: "24px" }}><b>Equifax<span className="Apple-converted-space">&nbsp;</span></b></td>
                                            <td valign="top" style={{ width: "1.615509%", height: "24px" }}><b>Experian<span className="Apple-converted-space">&nbsp;</span></b></td>
                                            <td valign="top" style={{ width: "65.051157%", height: "24px" }}><b>Trans Union<span className="Apple-converted-space">&nbsp;</span></b></td>
                                            </tr>
                                        <tr style={{ height: "24px" }}>
                                            <td valign="top" style={{ width: "72.267097%", height: "24px" }}>(800)-525-6285<span className="Apple-converted-space">&nbsp;</span></td>
                                            <td valign="top" style={{ width: "1.615509%", height: "24px" }}>(888)-397-3742<span className="Apple-converted-space">&nbsp;</span></td>
                                            <td valign="top" style={{ width: "65.051157%", height: "24px" }}>(800)-680-7289<span className="Apple-converted-space">&nbsp;</span></td>
                                            </tr>
                                        <tr style={{ height: "96px" }}>
                                            <td valign="top" style={{ width: "72.267097%", height: "96px" }}>
                                                    <p>P.O. Box 105069<span className="Apple-converted-space">&nbsp;</span></p>
                                                    <p>Atlanta, GA 30348<span className="Apple-converted-space">&nbsp;</span></p>
                                                </td>
                                            <td valign="top" style={{ width: "1.615509%", height: "96px" }}>
                                                    <p>P.O. Box 9532<span className="Apple-converted-space">&nbsp;</span></p>
                                                    <p>Allen, TX 75013<span className="Apple-converted-space">&nbsp;</span></p>
                                                </td>
                                            <td valign="top" style={{ width: "65.051157%", height: "96px" }}>
                                                    <p>P.O. Box 6790<span className="Apple-converted-space">&nbsp;</span></p>
                                                    <p>Fullerton, CA 92634<span className="Apple-converted-space">&nbsp;</span></p>
                                                </td>
                                            </tr>
                                        <tr style={{ height: "96px" }}>
                                            <td valign="top" style={{ width: "72.267097%", height: "96px" }}><a href="https://www.equifax.com%20/personal/credit-report-services/credit-fraud-alerts/&nbsp;"><span>https://www.equifax.com /</span>personal/credit-report-services/credit-fraud-alerts/<span className="Apple-converted-space">&nbsp;</span></a></td>
                                            <td style={{ width: "1.615509%", height: "96px" }}><a href="https://www.experian.com/fraud/center.html" rel="noopener">https://www.experian.com/fraud/center.html</a></td>
                                            <td style={{ width: "65.051157%", height: "96px" }}><a href="https://www.transunion.com/fraud-alerts&nbsp;" rel="noopener">https://www.transunion.com/fraud-alerts&nbsp;</a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p style={{textAlign: "left"}}><b>Security Freeze<span className="Apple-converted-space">&nbsp;</span></b></p>
                                    <p style={{textAlign: "left"}}>Some state laws allow you to place a security freeze on your credit reports. A security freeze would prohibit a credit reporting agency from releasing any information from your credit report without your written permission. You should be aware that placing a security freeze on your credit report may delay, interfere with, or prevent the timely approval of any requests you make for new loans, credit, mortgages, employment, housing, or other services. The specific costs and procedures for placing a security freeze vary by state. You can find additional information on the websites of any of the three credit reporting agencies listed above.<span className="Apple-converted-space">&nbsp;</span></p>
                                    <p style={{textAlign: "left"}}>If you believe that you have been a victim of identity theft and you provide the credit reporting agency with a valid police report, the agency will not charge you to place, lift, or remove a security freeze on your credit reports. In all other cases, a credit reporting agency may charge you a fee, which generally ranges from $5.00 to $20.00 per action.<span className="Apple-converted-space">&nbsp;</span></p>
                                    <p style={{textAlign: "left"}}>Requirements vary by state, but generally you may place a security freeze on your credit report by sending a written request to each of the three credit reporting agencies noted below, which may require the following information to verify your identity:<span className="Apple-converted-space">&nbsp;</span></p>
                                    <ol style={{textAlign: "left"}}>
                                        <li>Full name (including middle initial as well as Jr., Sr., II, III, etc.);<span className="Apple-converted-space">&nbsp;</span></li>
                                        <li>Social security number;<span className="Apple-converted-space">&nbsp;</span></li>
                                        <li>Date of birth;<span className="Apple-converted-space">&nbsp;</span></li>
                                        <li>Addresses for the prior five years;<span className="Apple-converted-space">&nbsp;</span></li>
                                        <li>Proof of current address; and<span className="Apple-converted-space">&nbsp;</span></li>
                                        <li>Legible copy of a government-issued identification card.<span className="Apple-converted-space">&nbsp;</span></li>
                                    </ol>
                                    <p style={{textAlign: "left"}}>You also may provide a copy of any relevant police report, investigative report, or complaint to a law enforcement agency concerning the incident.<span className="Apple-converted-space">&nbsp;</span></p>
                                    <p style={{textAlign: "left"}}><b>Police Report<span className="Apple-converted-space">&nbsp;</span></b></p>
                                    <p style={{textAlign: "left"}}>If you find suspicious activity on your credit reports or account statements or have reason to believe that your personal information is being misused, contact your local law enforcement authorities immediately and file a police report. You have the right to request a copy of the police report and should retain it for further use, as creditors may request such documentation to waive your potential liabilities in connection with fraudulent activity.<span className="Apple-converted-space">&nbsp;</span></p>
                                    <p style={{textAlign: "left"}}>150050629.2<span className="Apple-converted-space">&nbsp;</span></p></div>
                            </div>

                        {/*</ul>*/}

                    </div>
                </div>

            </div>
        );
    }
}
import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/pages/Home';
import { AboutUs } from './components/pages/AboutUs';
import { Options } from './components/pages/Options';
import { Researchers } from './components/pages/Researchers';
import { Prepare } from './components/pages/Prepare';
import { Testimonials } from './components/pages/Testimonials';
import { Resources } from './components/pages/Resources';
import { ContactUs } from './components/pages/ContactUs';
import { PhysicianFAQ } from './components/pages/PhysicianFAQ';
import { DuringEEG } from './components/pages/DuringEEG';
import { Advantage } from './components/pages/Advantage';
import { PatientFAQ } from './components/pages/PatientFAQ';
import { Offices } from './components/pages/Offices';
import { Schedule } from './components/pages/Schedule';
import { Billing } from './components/pages/Billing';
import { Hippa } from './components/pages/Hippa';
import './custom.css'

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Route exact path='/' component={Home} />
                <Route path='/about-us' component={AboutUs} />
                <Route path='/options' component={Options} />
                <Route path='/researchers' component={Researchers} />
                <Route path='/prepare' component={Prepare} />
                <Route path='/testimonials' component={Testimonials} />
                <Route path='/resources' component={Resources} />
                <Route path='/contact-us' component={ContactUs} />
                <Route path='/physician-faq' component={PhysicianFAQ} />
                <Route path='/during-eeg' component={DuringEEG} />
                <Route path='/advantage' component={Advantage} />
                <Route path='/patient-faq' component={PatientFAQ} />
                <Route path='/offices' component={Offices} />
                <Route path='/schedule' component={Schedule} />
                <Route path='/billing' component={Billing} />
                <Route path='/hippa' component={Hippa} />
            </Layout>
            
        );
    }
}

import React, { Component } from 'react';
import '../Pages.css';

export class Advantage extends Component {
    static displayName = Advantage.name;

    render() {
        return (
            <div class="banner-patients w3layouts">
                <div>
                    <p class="headerText">THE ANS ADVANTAGE</p>

                    <div class="container-pages">
                        <ul class="faqList paper">
                            <li>If you’ve been referred to ANS for an ambulatory or video ambulatory EEG, you’re in good hands.
                                ANS is one of the most experienced and highly-rated EEG providers in the United States. Our company
                                has performed thousands of EEGs just like the one prescribed by your physician and we have refined
                                the EEG process to ensure the safest and most comfortable experience available. </li>
                            <li>ANS does not perform sleep studies or cardiac monitoring or other diagnostic testing that would
                                dilute our focus: our company is 100% committed to performing the safest, highest-quality and most
                                comfortable EEG experience.</li>
                            <li>Our technicians are all registered with the ABRET (or in an ABRET training program) and receive
                                extensive training specifically for in-home EEGs. This ensures your study will be performed by
                                someone with both training in EEGs and specialized knowledge in how to make your in-home experience
                                the best possible. </li>

                            <li> Our technicians use the most current and clinically effective data collection methodologies to
                                make sure your study produces useful and accurate information for your physician. Our data
                                collection process ensures that your data is safely stored and our remote monitoring system ensures
                                that any issues that arise are fixed and the study continues.</li>
                            <li>Our remote monitoring technicians will continue checking on your study during its duration and, if
                                necessary, dispatch a local technician to repair any problems.</li>
                            <li>Once we have completed the data collection portion of the study, your EEG is evaluated by a
                                registered technician with no less than 20 years’ experience examining and preparing EEG studies
                                for neurologists. Experience is an important quality of an EEG technician because Epilepsy and
                                other disorders detected by EEG present in so many different ways: there are dozens of seizure
                                types and Epilepsy diagnoses. Our technicians that evaluate your EEG data and prepare it for your
                                physician have spent years in EEG labs helping neurologists identify and classify the many forms of
                                epilepsy and this experience pays off when handling your EEG data.</li>
                            <li>Unlike our competitors who rely on third-party data management or outsourced servers, ANS is a
                                technology leader and maintains its own proprietary network to ensure your data is seen only by
                                your doctor and our technicians. Your physician can access your data securely in a HIPAA-compliant
                                environment 24/7 and is always at your physician’s fingertips if he needs to access it.</li>

                        </ul>

                    </div>
                </div>

            </div>
        );
    }
}
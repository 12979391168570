import React, { Component } from 'react';
import '../Pages.css';

export class DuringEEG extends Component {
    static displayName = DuringEEG.name;

    render() {
        return (
            <div class="banner-patients w3layouts">
                <div>
                    <p class="headerText">DURING YOUR EEG</p>

                    <div class="container-pages">
                        <ul class="faqList paper">
                            <li>Avoid scratching the electrodes on your head! Scratching your head creates interference with the
                                EEG
                                results.</li>
                            <li>Press the event button if /when you experience any symptoms that might be related to your spells
                                and/or seizures. You only need to push the button, once, sometime after the start of the
                                symptom(s). You should note the time that the spell and/or seizure started and ended, if possible,
                                but do not press the button again. </li>
                            <li>Take the recorder off your shoulder and set it next to you in bed or on the couch when you are
                                sleeping. Do not place the recorder on the floor or on a table next to your bed while you sleep.</li>
                            <li>Do not sleep with an electric blanket, use a heating pad or sleep on a waterbed during the
                                recording time.</li>
                            <li>Avoid chewing gum or food excessively such as eating popcorn for prolonged periods of time.</li>
                            <li>Take prescribed medications as ordered by your Health Care Provider.</li>
                            <li>Do not take a bath, shower, swim, or go in a sauna or hot tub. Likewise, do not go to the beach or
                                sunbathe.</li>
                            <li>Avoid exposure to rain or bad weather.</li>
                            <li>Do not remove or pull on the cables or tamper with the equipment.</li>
                            <li>If you have any questions or concerns regarding your test please dial: 888-447-5904 ext #3.</li>
                        </ul>

                    </div>
                </div>

            </div>
        );
    }
}
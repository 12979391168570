import React, { Component } from 'react';
import '../Pages.css';

export class Prepare extends Component {
    static displayName = Prepare.name;

    render() {
        return (
            <div class="banner-patients w3layouts">
                <div>
                    <p class="headerText">PREPARING FOR YOUR VIDEO EEG</p>

                    <div class="container-pages">
                        <ul class="faqList paper">

                            <li>Once you have scheduled a date for your video EEG, you should make sure that you are ready to stay
                                at home for the majority of the time you will be wearing your EEG. Although the device you will be
                                wearing is portable and allows you to move freely inside and outside of your home, your clinical
                                status will be recorded via a video camera that you should stay in view of as much as possible.</li>

                            <li>Your hair needs to be clean and dry the day of the test. Please wash your hair the night before the
                                test or morning of the test, allowing for at least two hours for your hair and scalp to be
                                completely dry</li>
                            <li>If you have extensions or weaves, please call us to discuss in advance so we make sure we can work
                                with your hair.</li>
                            <li>Do not use any hair products such as gels, mousse, or hairspray after you have washed your hair. If
                                you are bald and use skin products on your scalp, do not apply any after you have washed your hair
                                – your scalp needs to be free of any conditioners, moisturizers, or skin treatments. </li>
                            <li>Please wear a button- down shirt for the test. Once the electrodes are put on, you should not pull
                                anything over your head. This will help keep the electrodes in place during the test</li>
                            <li>Keep taking your regular medicines. Have a list of your these medicines ready for the technician
                                who comes to your house.</li>
                        </ul>

                    </div>
                </div>

            </div>
        );
    }
}
import React, { Component } from 'react';
import { TestSlide } from '../TestSlide';
import { DynamicLinks } from '../DynamicLinks';
import '../Home.css';

export class Home extends Component {
    static displayName = Home.name;

    render() {
        return (
            <div class="banner_w3ls w3layouts">
                <div>
                    <div id="top" class="callbacks_container">
                        <div class="banner-info">
                            <div class="banner-text">
                                <h3>Welcome!</h3>
                                <p>We are a leading provider of EEG testing throughout the United States.
                                    Please explore our site to learn more about how we help patients and physicians
                                    manage epilepsy, seizures and other neurological disorders. </p>
                            </div>

                        </div>
                        <TestSlide class="rslides" ></TestSlide>
                        <DynamicLinks></DynamicLinks>
                    </div>
                </div>


            </div>
        );
    }
}

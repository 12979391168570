import React, { Component } from 'react';
import '../Pages.css';
import EpilepsyFoundation from '../../images/EpilepsyFoundation.png';
import CURE from '../../images/CURE.png';
import NAEC from '../../images/NAEC.png';
import DannyDid from '../../images/DannyDid.png';
import EpilepsyAdvocate from '../../images/DannyDid.png';
import DravetSyndrome from '../../images/DravetSyndrome.png';
import InfantileSpasms from '../../images/InfantileSpasms.png';
import LifeLines from '../../images/LifeLines.png';
import LivingWell from '../../images/LivingWell.png';
import AmericanEpilepsySociety from '../../images/AmericanEpilepsySociety.png';
import SeizureTracker from '../../images/SeizureTracker.png';

export class Resources extends Component {
    static displayName = Resources.name;

    render() {
        return (
            <div>
                <div class="resources-header">
                    <h4> The following organizations are part of the greater epilepsy community and are a good place for information in your quest to understand epilepsy and seizures. Clicking on these links will take you to external sites not managed by Ambulatory Neurological Services, LLC.
                    </h4>
                </div>
                <br /><br />
                <div class="resourcesImg">
                    <div>
                        <a href="https://www.epilepsy.com/"><img src={EpilepsyFoundation} /></a>
                        <a href="https://www.cureepilepsy.org/"><img src={CURE} /></a>
                        <a href="https://www.naec-epilepsy.org/about-epilepsy-centers/what-is-an-epilepsy-center/"><img src={NAEC} /></a>
                    </div>
                    <br />
                    <div>
                        <a href="https://www.dannydid.org/"><img src={DannyDid} /></a>
                        <a href="https://www.dravetfoundation.org/"><img src={DravetSyndrome} /></a>
                    </div>
                    <br />
                    <div>
                        <a href="http://infantilespasmsproject.org/"><img src={InfantileSpasms} /></a>
                        <a href="https://www.lifelinesneuro.com/"><img src={LifeLines} /></a>
                    </div>
                    <br />
                    <div>
                        <a href="https://livingwellwithepilepsy.com/start-here"><img src={LivingWell} /></a>
                        <a href="https://www.aesnet.org/"><img src={AmericanEpilepsySociety} /></a>
                        <a href="https://www.seizuretracker.com/"><img src={SeizureTracker} /></a>
                    </div>
                    <br />
                </div>

            </div>
        );
    }
}
import React, { Component } from 'react';
import '../Pages.css';

export class Options extends Component {
    static displayName = Options.name;

    
    render() {
        return (
            <div>
                <div class="banner" >
                    <p class="headerText">EEG OPTIONS FOR PHYSICIANS</p>

                    <div class="container-pages">
                        <ul class="faqList paper">

                            <li>
                                <p class="double-spaced">ANS is the preferred choice for physicians needing in-home and
                                    facility-based EEGs throughout the United States. ANS can provide your patients with a safe,
                                    high-quality, and comfortable alternative to hospitalizing your patients in an EMU setting.
                                    We can also perform routine EEGs at your office or the patient’s home as well and both our
                                    long-term and routine EEGs are presented to you over the Internet available 24/7 on a custom
                                    software platform designed to streamline your workload and help you finish your dictation as
                                    accurately and efficiently as possible.
                                </p>
                            </li>
                            <li>
                                <p class="double-spaced">We offer Physicians the ability to add remotely-monitored video EEG to
                                    their practice and improve patient outcomes. Call us for more information about options for
                                    your practice: 888-447-5904. </p>
                            </li>

                        </ul>

                    </div>
                </div>
            </div>

        );
    }
}
import React, { Component } from 'react';
import '../Pages.css';

export class PatientFAQ extends Component {
    static displayName = PatientFAQ.name;

    render() {
        return (
            <div class="banner-patients w3layouts">
                <div>
                    <p class="headerText">PATIENTS FAQ</p>

                    <div id="faqContainer" class="container-pages">
                        <ul id="faqList" class="paper faqList">

                            <li>
                                <p>
                                    <b class="FAQhead">Why did my Doctor order an EEG?</b> <br /><br />
                                    <p class="indented">Physicians use EEGs to gather neurological information and assist them in
                                        diagnosing a variety of issues and conditions. The most common reason for ordering an
                                        ambulatory EEG is to help understand why a patient is having seizures.</p>
                                </p><br />
                                <p class="FAQend"></p>
                                <br />
                            </li><br />
                            <li>
                                <p>
                                    <b class="FAQhead">Why did my Doctor choose ANS?</b> <br /><br />
                                    <p>Your physician chose ANS for your EEG because they trust ANS to provide them an accurate,
                                        safe and comfortable procedure. They know ANS excels in these areas:</p>
                                    <p class="indented">
                                        <b><u>Personnnel</u></b><br />
                                        We employ some of the most experienced people in the industry. Our medical directors each
                                        have over 15 years’ experience in neurology and our lead technician has over 20 years of
                                        experience in performing EEGs and managing EEG staff. We are one of the few EEG providers
                                        who train their own technicians in the specifics of in-home EEGs. <br /><br />
                                        <b><u>Technology</u></b><br />
                                        We are an independent provider of EEG services so we are free to choose the best equipment
                                        and software available for our studies. Some providers are tied to hardware or software
                                        companies and must use their equipment or software. We always choose the best resources
                                        available and constantly strive to improve the patient experience.<br /><br />
                                        <b><u>Insurance</u></b><br />
                                        We understand how important it is for patients to know how their insurance will treat their EEG.
                                        We are contracted with most major insurance providers (in-network) and will help you understand how much of your test cost will be your responsibility.
                                        If you have any questions about your current coverage, call us and we'll help.</p>

                                </p><br />
                                <p class="FAQend"></p>
                                <br />
                            </li><br />
                            <li>
                                <p>
                                    <b class="FAQhead">How do I prepare for my EEG?</b> <br /><br />
                                    <p class="indented"> When you schedule an ambulatory EEG with ANS, the scheduler will give you
                                        detailed information
                                        on
                                        how to prepare for the
                                        appointment and how to take care of yourself and the equipment during your study.
                                        Here are some of those items you will want to focus on:</p>
                                    <br />

                                    <ul class="faqSubList patient-list">
                                        <li>Once you have scheduled a date for your video EEG, you should make sure that you are
                                            ready to stay at home for the majority of the time you will be wearing your EEG.
                                            Although the device you will be wearing is portable and allows you to move freely
                                            inside and outside of your home, your clinical status will be recorded via a video
                                            camera that you should stay in view of as much as possible. </li>
                                        <li>Your hair needs to be clean and dry the day of the test. Please wash your hair the
                                            night before the test or morning of the test, allowing for at least two hours for your
                                            hair and scalp to be completely dry.</li>
                                        <li>If you have extensions or weaves, please call us to discuss in advance so we make sure
                                            we can work with your hair.</li>
                                        <li>Do not use any hair products such as gels, mousse, or hairspray after you have washed
                                            your hair.
                                            If you are bald and use skin products on your scalp, do not apply any after you have
                                            washed
                                            your hair –
                                            your scalp needs to be free of any conditioners, moisturizers, or skin treatments. </li>
                                        <li>Please wear a button- down shirt for the test. Once the electrodes are put on, you
                                            should
                                            not pull anything over
                                            your head. This will help keep the electrodes stay in place during the test.</li>
                                        <li>Keep taking your regular medicines. Have a list of your these medicines ready for the
                                            tech who comes to your house.</li>
                                    </ul>
                                </p><br />
                                <p class="FAQend"></p>
                                <br />
                            </li>
                            <li>
                                <p>
                                    <b class="FAQhead">What am I supposed to do (not do) during the EEG?</b> <br /><br />

                                    <ul class="faqSubList patient-list">
                                        <li>Avoid scratching the electrodes on your head! Scratching your head creates
                                            interference
                                            with the EEG results.</li>
                                        <li>Press the event button if /when you experience any symptoms that might be related to
                                            your spells and/or seizures. You only need to push the button, once, sometime after the
                                            start of the symptom(s). You should note the time that the spell and/or seizure started
                                            and ended, if possible, but do not press the button again.</li>
                                        <li>Take the recorder off your shoulder and set it next to you in bed or on the couch
                                            when you are sleeping. Do not place the recorder on the floor or on a table next to
                                            your bed while you sleep.. </li>
                                        <li>Do not sleep with an electric blanket, use a heating pad or sleep on a waterbed
                                            during the recording time.</li>
                                        <li>Avoid chewing gum or food excessively such as eating popcorn for prolonged periods of
                                            time. </li>
                                        <li>Take prescribed medications as ordered by your Health Care Provider. </li>
                                        <li>Do not take a bath, shower, swim, or go in a sauna or hot tub. Likewise, do not go to
                                            the
                                            beach or sunbathe. </li>
                                        <li>Avoid exposure to rain or bad weather. </li>
                                        <li>Do not remove or pull on the cables or tamper with the equipment. </li>
                                        <li>If you have any questions or concerns regarding your test please dial: 888-447-5904 ext
                                            #3. </li>
                                    </ul>
                                </p><br />
                                <p class="FAQend"></p>
                                <br />
                            </li>
                            <li>
                                <p>
                                    <b class="FAQhead">Will the technician explain the test to me when they come to my
                                        home?</b>
                                    <br /><br />
                                    <p class="indented"> Yes. The technician who comes to your home or residential facility to set
                                        up your EEG will explain the process to you and collect all relevant information prior to
                                        beginning the study. The Technician will also leave with you a pamphlet explaining what to
                                        do and not to do during your EEG.</p>

                                </p><br />
                                <p class="FAQend"></p>
                                <br />
                            </li><br />
                            <li>
                                <p>
                                    <b class="FAQhead">Do I really need to spend my time during my EEG in front of video
                                        camera?</b> <br /><br />
                                    <p class="indented"> Your physician will use the video recording to look at your physical
                                        condition (clinical presentation) during any periods of concerning or abnormal EEG activity
                                        in order to understand and diagnose your symptoms. This means you risk losing valuable
                                        information when you are spending time away from your video recording device in the event
                                        you experience neurological symptoms during this time.</p>

                                </p><br />
                                <p class="FAQend"></p>
                                <br />
                            </li><br />
                            <li>
                                <p>
                                    <b class="FAQhead">Who sees my video camera recording?</b> <br /><br />
                                    <p class="indented">Your video recording is reviewed by our data preparation technicians and
                                        your physician when we find EEG data that would be of interest to your physician and only
                                        those portions of the video correlating to those areas of EEG interest are reviewed.</p>

                                </p><br />
                                <p class="FAQend"></p>
                                <br />
                            </li><br />
                            <li>
                                <p>
                                    <b class="FAQhead">Is audio recorded during my video EEG? </b> <br /><br />
                                    <p class="indented">Although we do have the capability to record audio, we have found that
                                        audio recordings for in-home EEGs provide little incremental value to the physician (based
                                        on physician feedback). Furthermore, patients generally prefer to not have their background
                                        conversations recorded for privacy reasons.</p>

                                </p><br />
                                <p class="FAQend"></p>
                                <br />
                            </li><br />
                            <li>
                                <p>
                                    <b class="FAQhead">Where can I Find More Information about Epilepsy or Seizures?</b>
                                    <br /><br />
                                    <p class="indented">If you are looking for more information on Epilepsy and seizures, there are
                                        a number of useful websites on our resources page that can help you.</p>

                                </p><br />
                                <p class="FAQend"></p>
                                <br />
                            </li><br />
                        </ul>
                    </div>
                </div>

            </div>
        );
    }
}
import React, { Component } from 'react';
import {
    Button,
    Form,
    FormGroup,
    Input,
    Label
} from 'reactstrap';
import * as emailjs from 'emailjs-com'
import '../Pages.css';

export class Billing extends Component {
    static displayName = Billing.name;

    state = {
        name: '',
        email: '',
        message: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipCode: '',
        daytimePhone: '',
        eveningPhone: '',

    }
    handleSubmit(e) {
        e.preventDefault()
        const { name, email, address1, address2, city, state, zipCode, daytimePhone, eveningPhone, message } = this.state
        let templateParams = {
            from_name: email,
            to_name: 'info@anseeg.com',
            name: name,
            address1: address1,
            address2: address2,
            city: city,
            state: state,
            zipCode: zipCode,
            daytimePhone: daytimePhone,
            eveningPhone: eveningPhone,
            message: message,
        }
        emailjs.send(
            'service_k449o3p',
            'template_r5y6xyw',
            templateParams,
            'S63Ujgm9-CgPmkXax'
        )
        this.resetForm()
    }
    resetForm() {
        this.setState({
            name: '',
            email: '',
            message: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            zipCode: '',
            daytimePhone: '',
            eveningPhone: '',
        })
    }
    handleChange = (param, e) => {
        this.setState({ [param]: e.target.value })
    }

    render() {
        return (
            <div>
                <div class="banner-contact">
                    <div>
                        <p class="headerText">BILLING</p>
                        
                    </div>
                    <div>
                        <h4>If you have any questions about your bill, please call us at <b>888-447-5904 x2</b><br /> or fill out the form below.</h4>
                    </div>
                    <div className="contact-form">

                        <Form className="form paper" onSubmit={this.handleSubmit.bind(this)}>
                            <p><b>To pay your outstanding ANS bill, please click here: </b>
                                <a href="https://pay.instamed.com/Form/PaymentPortal/Default?id=anseeg" > <b>InstaMed ANS Portal</b></a>
                            </p>
                            <FormGroup class="FormGroup" controlId="formBasicEmail">
                                <Input
                                    type="text"
                                    name="name"
                                    id="name"
                                    placeholder="Full Name"
                                    value={this.state.name}
                                    onChange={this.handleChange.bind(this, 'name')}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    type="email"
                                    name="email"
                                    id="email"
                                    placeholder="Email Address"
                                    value={this.state.email}
                                    onChange={this.handleChange.bind(this, 'email')}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    type="text"
                                    name="address1"
                                    id="address1"
                                    placeholder="Address Street 1"
                                    value={this.state.address1}
                                    onChange={this.handleChange.bind(this, 'address1')}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    type="text"
                                    name="address2"
                                    id="address2"
                                    placeholder="Address Street 2"
                                    value={this.state.address2}
                                    onChange={this.handleChange.bind(this, 'address2')}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    type="text"
                                    name="city"
                                    id="city"
                                    placeholder="City"
                                    value={this.state.city}
                                    onChange={this.handleChange.bind(this, 'city')}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    type="text"
                                    name="state"
                                    id="state"
                                    placeholder="State"
                                    value={this.state.state}
                                    onChange={this.handleChange.bind(this, 'state')}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    type="text"
                                    name="zipCode"
                                    id="zipCode"
                                    placeholder="ZIP Code"
                                    value={this.state.zipCode}
                                    onChange={this.handleChange.bind(this, 'zipCode')}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    type="text"
                                    name="daytimePhone"
                                    id="daytimePhone"
                                    placeholder="Daytime Phone"
                                    value={this.state.daytimePhone}
                                    onChange={this.handleChange.bind(this, 'daytimePhone')}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    type="text"
                                    name="eveningPhone"
                                    id="eveningPhone"
                                    placeholder="Evening Phone"
                                    value={this.state.eveningPhone}
                                    onChange={this.handleChange.bind(this, 'eveningPhone')}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    type="textarea"
                                    name="message"
                                    id="message"
                                    placeholder="Comments"
                                    value={this.state.message}
                                    onChange={this.handleChange.bind(this, 'message')}

                                />
                            </FormGroup>
                            <div class="contact-submit">
                                <Button variant="primary" type="submit">Submit</Button>
                            </div>
                            <div>
                                {this.state.mailSent &&
                                    <div>Thank you for contcting us.</div>
                                }
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        );
    }
}
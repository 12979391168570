import React, { Component } from 'react';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption
} from 'reactstrap';
import './TestSlide.css';

const items = [
    {
        content: 'I was hesitant to do my EEG because I thought it would be painful or at least very uncomfortable. My EEG was actually very tolerable and the setup and monitoring did not hurt at all. My technician was also very nice and helpful during the setup explaining everything to me and eased my concerns greatly.',
        author: 'Fatima M, Kentucky'
    },

    {
        content: 'Thank you for providing such a great service! Doing my EEG at home instead of in the hospital was a huge relief. If I ever need an EEG again, I will make sure my doctor chooses ANS.',
        author: 'Margaret R., Milwaukee, Wisconsin'
    },

    {
        content: 'ANS provides my patients with a comfortable, high-quality EEG that I can rely on time after time.',
        author: 'Dr. Michael Hammer, MD'
    },

    {
        content: 'We live three hours from a major hospital and it takes a whole day to drive our daughter to and from the doctor whenever she needs an EEG. Long - term monitoring is even harder because we have to stay in the hospital with her during the monitoring and it means my husband or I have to take off work. ANS came to our home and did the setup without any issues and then remotely monitored the study to make sure everything was OK.What a great service!',
        author: 'Mona P, Idaho'
    },

    {
        content: 'I have worked with multiple EEG providers over the years and ANS studies are the best I’ve seen. The video quality of their studies is unparalleled in the industry and distinguishes them among video EEG providers.',
        author: 'Dr. Christopher Inglese, MD and Epileptologist'
    },

    {
        content: 'I am so glad our doctor ordered our EEG with ANS. Our son has epilepsy and has had several in-home EEGs and they haven’t always gone well.Our ANS technician was so patient and careful with our son, he was able to stay calm and happy through the whole setup.Thanks for a great experience!',
        author: 'Jennifer G. Barrington, Illinois'
    },

    {
        content: 'As an epileptologist, I need high-quality EEG data delivered in a secure environment. Thank you ANS for a great EEG and a great system!',
        author: 'Dr. Rajinder Singh, DO and Epileptologist'
    },

    {
        content: 'I am writing to let you know the tech who did my EEG was great. She explained everything about the EEG before she started and she took the time to go over everything again at the end.I would recommend using your company to anyone who needs an EEG.',
        author: 'Brenda B. Dayton, Ohio'
    },

    {
        content: 'I expect a lot from an EEG service: quick scheduling, great technicians, accurate data preparation, and a review system that works everywhere I work. ANS delivers on my expectations and, more importantly, they help improve my level of patient care: that’s why I have been using them for almost ten years.',
        author: 'Dr. Sasidharan Taravath, MD'
    },

    {
        content: 'The whole EEG process was simple and much more comfortable than I expected. After wearing the EEG for a couple hours, I hardly noticed I still had it on. Sleeping was also much better than I expected and not a problem at all.',
        author: 'Martha E, West Virginia'
    },

    {
        content: 'There is great utility in ambulatory, digital video-EEG monitoring. If often allows for characterization and diagnosis of clinical events of concern, seizure classification, seizure quantification and even preliminary presurgical screening for epilepsy surgery.This is all accomplished in a cost- effective manner and in a setting that may be more conducive to eliciting the events of concern and may be better tolerated than an inpatient setting.In my opinion ambulatory EEG is very useful in the management of epilepsy.',
        author: 'Dr. Colin Van Orman, MD'
    },

    {
        content: 'I’m glad my doctor referred us to ANS. Our son has a rare form of epilepsy and we have had many EEGs both in the hospital and in our home. None of those EEGs went as smoothly as the one performed by ANS.We also requested a data disc to keep the information for our son’s permanent health records and your company got it to us quickly and without any hassle, unlike some of the other providers we have used.Thanks again.',
        author: 'Joseph N, North Carolina'
    },
    
];

export class TestSlide extends Component {
    constructor(props) {
        super(props);
        this.state = { activeIndex: 0 };
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }

    render() {
        const { activeIndex } = this.state;

        const slides = items.map((item) => {
            return (
                <CarouselItem
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={item.content}
                >
                    {/*<img src={item.content} alt={item.altText} />*/}
                    <div className="TestSlide-Content">
                        <span className="testi-content">{`"${item.content}"`}</span>
                        <br/>
                        <span className="testi-content-author">{`-`}{item.author}</span>
                        <CarouselCaption captionText={item.caption} captionHeader={item.caption} />
                    </div>
                </CarouselItem>
            );
        });

        return (
            

            <Carousel
                activeIndex={activeIndex}
                next={this.next}
                previous={this.previous}
            >
                <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                {slides}
                <CarouselControl direction="prev" directionText="Prev" onClickHandler={this.previous} />
                <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                </Carousel>
                
        );
    }
}
